import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'content', 'trigger' ]

  connect() {
    this.toggle()
  }

  toggle() {
    const isChecked = this.triggerTarget.querySelector('input[value="true"]').checked
    this.contentTarget.classList.toggle('vesta-display-none', !isChecked)
  }
}
