import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dynamicContent']

  connect() {
    this.currentIndex = 0
    this.startRotation()
  }

  disconnect() {
    if (this.interval) clearInterval(this.interval)
  }

  startRotation() {
    this.interval = setInterval(() => {
      this.rotate()
    }, 4000)
  }

  rotate() {
    this.dynamicContentTargets[this.currentIndex].classList.remove('visible')
    this.currentIndex = (this.currentIndex + 1) % this.dynamicContentTargets.length
    this.dynamicContentTargets[this.currentIndex].classList.add('visible')
  }
}
