import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggleLinkedPortal(event) {
    const portalPairs = {
      'BELLES_DEMEURES': 'LUX_RESIDENCE',
      'LUX_RESIDENCE': 'BELLES_DEMEURES',
      'SELOGER': 'LOGIC_IMMO',
      'LOGIC_IMMO': 'SELOGER'
    }

    const checkbox = event.target
    const linkedPortal = portalPairs[checkbox.value]

    if (linkedPortal) {
      const linkedCheckbox = this.element.querySelector(`input[value="${linkedPortal}"]`)
      if (linkedCheckbox) linkedCheckbox.checked = checkbox.checked
    }
  }
}
