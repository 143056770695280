import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'vabInfos',
    'technicalInfos',
    'keysInfos',
    'visitorFields',
    'counterVisitCheckbox',
    'emailInput',
    'selectLead',
    'submitButton',
    'selectInput'
  ]

  static values = { saleDead: Boolean }

  connect() {
    if(this.hasSaleDeadValue && this.saleDeadValue) this.manageTechnicalVisit();
  }

  manageClassicVisit = () => {
    this.toggleVisitorFields('show');
    this.vabInfosTarget.classList.add('hidden');
    this.selectLeadTarget.classList.add('hidden');
    this.emailInputTarget.removeAttribute('disabled');
    this.technicalInfosTarget.classList.add('hidden');
    this.keysInfosTarget.classList.add('hidden');
    this.handleDisabledButton()
    if (this.hasCounterVisitCheckboxTarget) {
      this.counterVisitCheckboxTarget.checked = false;
    }
  }

  manageGuideVisit = () => {
    this.toggleVisitorFields('hide')
    this.vabInfosTarget.classList.remove('hidden');
    this.selectLeadTarget.classList.add('hidden');
    this.technicalInfosTarget.classList.add('hidden');
    this.keysInfosTarget.classList.add('hidden');
    this.handleDisabledButton()
    if (this.hasCounterVisitCheckboxTarget) {
      this.counterVisitCheckboxTarget.checked = false;
    }
  }

  manageCounterVisit = (event) => {
    this.vabInfosTarget.classList.add('hidden');
    this.toggleVisitorFields('hide')
    this.selectLeadTarget.classList.remove('hidden');
    this.technicalInfosTarget.classList.add('hidden');
    this.keysInfosTarget.classList.add('hidden');
    this.emailInputTarget.toggleAttribute('disabled');
    if (this.hasSelectInputTarget) {
      this.handleDisabledButton(event.srcElement.checked && this.selectInputTarget.value === '');
    }
    if (this.hasCounterVisitCheckboxTarget) {
      this.counterVisitCheckboxTarget.checked = true;
    }
  }

  manageTechnicalVisit = () => {
    this.toggleVisitorFields('hide')
    this.vabInfosTarget.classList.add('hidden');
    if (this.hasSelectLeadTarget) {
      this.selectLeadTarget.classList.add('hidden');
    }
    this.handleDisabledButton()
    if (this.hasCounterVisitCheckboxTarget) {
      this.counterVisitCheckboxTarget.checked = false;
    }
    this.keysInfosTarget.classList.add('hidden');
    this.technicalInfosTarget.classList.remove('hidden');
  }

  manageKeysVisit = () => {
    this.toggleVisitorFields('hide')
    this.vabInfosTarget.classList.add('hidden');
    if (this.hasSelectLeadTarget) {
      this.selectLeadTarget.classList.add('hidden');
    }
    this.handleDisabledButton()
    if (this.hasCounterVisitCheckboxTarget) {
      this.counterVisitCheckboxTarget.checked = false;
    }
    this.technicalInfosTarget.classList.add('hidden');
    this.keysInfosTarget.classList.remove('hidden');
  }

  updateSubmitButton = () => {
    this.handleDisabledButton(!this.selectInputTarget.value != '')
  }

  handleDisabledButton = (toDisabled) => {
    if(toDisabled) this.submitButtonTarget.setAttribute('disabled', '')
    else this.submitButtonTarget.removeAttribute('disabled')
  }

  toggleVisitorFields = (state) => {
    if (!this.hasVisitorFieldsTarget) return;
    if (state) {
      state === 'show' ? this.visitorFieldsTarget.classList.remove('hidden') : this.visitorFieldsTarget.classList.add('hidden');
    }
    else {
      this.visitorFieldsTarget.classList.toggle('hidden');
    }
  }
}
