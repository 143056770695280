import Swiper from 'swiper/bundle';
import { fetchContent } from '../../../api/partials'

const PARTIALS = {
  async updatePageData({ mapOnly, baseUrl, step_type, record_id, record_type, property_kind = null }) {
    if (step_type === 'france') {
      window.location.href = 'prix-immobilier-m2';
      return;
    }

    // UPDATE RIGHT BLOCK
    const dynamicContent = document.getElementById('vesta-dynamic-content-ref');
    // UPDATE TITLE
    const dynamicTitleH1 = document.getElementById('vesta-dynamic-title-h1');
    const dynamicTitleH2 = document.getElementById('vesta-dynamic-title-h2');
    // UPDATE LAST PROPERTY SOLD
    const dynamicSoldProperties = document.getElementById('vesta-dynamic-last-property-sold');
    // UPDATE OTHER PRICING ZONES
    const dynamicPricingZones = document.getElementById('vesta-dynamic-pricing-zones');
    const pricingTopCities = document.getElementById('vesta-pricing-top-cities');
    const pricingQuarters = document.getElementById('vesta-pricing-quarters');
    // Remove specials city faq partials as content is not replaced if on other step (uniq display for city step)
    pricingTopCities.innerHTML = '';
    pricingQuarters.innerHTML = '';
    // UPDATE FAQS
    const dynamicFaqs = document.getElementById('vesta-dynamic-faqs');

    handleFetchData({
      url: document.location.origin + `${baseUrl}/render_zone_partial`,
      body: JSON.stringify({ step_type, record_id, record_type, property_kind }),
      anchorContent: dynamicContent
    });

    handleFetchData({
      url: document.location.origin + `${baseUrl}/render_title_h1_partial`,
      body: JSON.stringify({ step_type, record_id, record_type, path: window.location.pathname }),
      anchorContent: dynamicTitleH1
    });

    if (mapOnly) return;

    handleFetchData({
      url: document.location.origin + `${baseUrl}/render_title_h2_partial`,
      body: JSON.stringify({ step_type, record_id, record_type, path: window.location.pathname }),
      anchorContent: dynamicTitleH2
    });

    handleFetchData({
      url: document.location.origin + `${baseUrl}/render_last_property_sold_partial`,
      body: JSON.stringify({ step_type, record_id, record_type }),
      anchorContent: dynamicSoldProperties
    })

    handleFetchData({
      url: document.location.origin + `${baseUrl}/render_pricing_zones_partial`,
      body: JSON.stringify({ step_type, record_id, record_type }),
      anchorContent: dynamicPricingZones
    })

    if (step_type === 'city') {
      handleFetchData({
        url: document.location.origin + `${baseUrl}/render_pricing_top_cities_partial`,
        body: JSON.stringify({ step_type, record_id, record_type }),
        anchorContent: pricingTopCities
      })

      handleFetchData({
        url: document.location.origin + `${baseUrl}/render_pricing_quarters_partial`,
        body: JSON.stringify({ step_type, record_id, record_type }),
        anchorContent: pricingQuarters
      })
    }

    handleFetchData({
      url: document.location.origin + `${baseUrl}/render_faqs_partial`,
      body: JSON.stringify({ step_type, record_id, record_type }),
      anchorContent: dynamicFaqs
    })
  },
};

export default PARTIALS;

async function handleFetchData({ url, body, anchorContent}) {
  anchorContent.classList.add('blurred');
  const data = await fetchContent(url, body);
  anchorContent.innerHTML = data

  setTimeout(() => {
    anchorContent.classList.remove('blurred');
  }, '200')

  // Refresh swiper to handle new value of @last_properties_sold
  refreshSwiper();
};

function refreshSwiper() {
  new Swiper('.last-sales-carousel', {
    slidesPerView: 1,
    spaceBetween: 40,
    centeredSlides: false,
    navigation: {
      nextEl: '.next-sale',
      prevEl: '.prev-sale'
    },
    breakpoints: {
      640: {
        centeredSlides: false,
        slidesPerView: 2,
        spaceBetween: 5
      },
      1024: {
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 100
      },
      1550: {
        centeredSlides: false,
        slidesPerView: 4,
        spaceBetween: 100
      }
    }
  });
};
