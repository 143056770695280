import { Controller } from '@hotwired/stimulus';
import SlimSelect from 'slim-select'

export default class extends Controller {
  static targets = [ 'clientFields', 'userSelect', 'classicVisitFields' ];

  initialize() {
    new SlimSelect({
      select: this.userSelectTarget,
      events: {
        search: (search) => {
          return new Promise((resolve) => {
    
            fetch(`/admin/clients?q[first_name_unaccent_or_last_name_unaccent_or_email_cont]=${search}`, {
              method: 'GET',
              headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
              .then((data) => {
                const options = data.map(item => {
                  const full_name = (item.first_name || item.last_name) ? [item.first_name, item.last_name].join(' ') : null
                  const text = [full_name, item.email].filter(n => n).join(' - ')
                  return { "value": item.id, "text": text }
                });
                resolve(options)
            })
          })
        }
      }
    })
  }

  showClientFields() {
    this.clientFieldsTarget.classList.toggle('vesta-display-none');
  }

  showClassicVisitFields(e) {
    if (e.target.value === 'classic') {
      this.classicVisitFieldsTargets.forEach(el => {
        el.classList.remove('vesta-display-none');
      })
    } else {
      this.classicVisitFieldsTargets.forEach(el => {
        el.classList.add('vesta-display-none');
      })
    }
  }
}
