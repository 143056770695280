import handleUrl from './utils/urlHandler';

export const bannerPropertyKindHtml = `
  <div class='vesta-estimation-banner'>
    <div class='link-obfuscation'
        data-controller='application--obfuscation-link'
        data-action='click->application--obfuscation-link#decodeUrlLinks'
        data-blank='true'
        data-encode="${btoa(handleUrl('estimation'))}">
      <div class='vesta-flex-responsive vesta-indigo-100'>
        <div class='vesta-estimation-banner-title'>
          <div class='typo-h2-mobile'>Quel type de bien <span class='vesta-no-break-title'>souhaitez-vous estimer ?<span></div>

          <div class='typo-subtitle-medium'>
            <img src='https://res.cloudinary.com/vesta-home/image/upload/v1651757324/assets/revamp/icons/check-green.svg' width='22' height='22'>
            Estimation gratuite en 2 minutes
          </div>
        </div>

          <div class="vesta-flex-col-custom">
            <div class="vesta-small-checkbox-card-container vesta-margin-r-15">
              <div id="apartment" class="vesta-custom-checkbox" data-checked="false">
                <label for="apartment" class="vesta-small-checkbox-card vesta-gap-10">
                  <svg-apartment class='vesta-flex-row-center'></svg-apartment>
                  Appartement
                </label>
              </div>
            </div>

            <div class="vesta-small-checkbox-card-container vesta-margin-r-15">
              <div id="house" class="vesta-custom-checkbox" data-checked="false">
                <label for="house" class="vesta-small-checkbox-card vesta-gap-10">
                  <svg-house class='vesta-flex-row-center'></svg-house>
                  Maison
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`.trim();

export const blockEstimationV1Html = `
  <div class='vesta-estimation-banner vesta-flex-col-center link-obfuscation'
      data-controller='application--obfuscation-link'
      data-action='click->application--obfuscation-link#decodeUrlLinks'
      data-blank='true'
      data-encode="${btoa(handleUrl('estimation'))}">
    <div class='typo-subtitle-bold vesta-indigo-100'>Vous souhaitez découvrir la <span class='vesta-blue-100'>valeur de votre bien ?</span></div>
    <div class='typo-content vesta-indigo-70 vesta-margin-b-15'>Obtenez une estimation 100% gratuite, en quelques clics seulement !</div>
    <div class='vesta-button--primary '>Découvrir l'estimation de mon bien</div>
  </div>
`.trim();

export const blockEstimationV2Html = `
  <div class='vesta-estimation-banner vesta-flex-col-center link-obfuscation'
      data-controller='application--obfuscation-link'
      data-action='click->application--obfuscation-link#decodeUrlLinks'
      data-blank='true'
      data-encode="${btoa(handleUrl('annonces', false))}">
    <div class='typo-subtitle-bold vesta-indigo-100'>Trouvez le bien de vos rêves parmi <span class='vesta-blue-100'>+800 biens à vendre</span></div>
    <div class='typo-content vesta-indigo-70 vesta-margin-b-15'>Un projet d'achat à venir ? Découvrez tous nos biens en vente</div>
    <div class='vesta-button--primary '>Voir tous nos biens en vente</div>
  </div>
`.trim();

export const borrowCapacityHtml = `
  <a href=${handleUrl('simulateur-emprunt', false)} target='_blank' class='vesta-estimation-banner vesta-flex-col-center'>
    <div class='typo-subtitle-bold vesta-indigo-100'>Vous souhaitez <span class='vesta-blue-100'>calculer votre capacité d'emprunt ?</span></div>
    <div class='typo-content vesta-indigo-70 vesta-margin-b-15'>Obtenez une estimation de votre capacité d'emprunt en quelques clics seulement !</div>
    <div class='vesta-button--primary '>Simuler ma capacité d'emprunt</div>
  </a>
`.trim();

export const bannerEstimationAddressHtml = `
  <div class='link-obfuscation'
      data-controller='application--obfuscation-link'
      data-action='click->application--obfuscation-link#decodeUrlLinks'
      data-blank='true'
      data-encode="${btoa(handleUrl('estimation'))}">
    <div class="vesta-card vesta-card-conversion-article-container vesta-background-radial vesta-padding-20">
      <div class="vesta-background-radial vesta-card-conversion">
        <div class="vesta-flex-col-start vesta-gap-25 vesta-w-full">
          <div class="typo-h2-mobile vesta-title vesta-w-full vesta-flex-row-center">
            <div class="vesta-max-w-title">Découvrez <span class="vesta-blue-100">l'estimation</span> de votre bien</div>
          </div>

          <div class="vesta-flex-col-start vesta-gap-10 vesta-w-full">
            <div id="stopPropagationBlock" class="vesta-w-full">
              <div class="vesta-estimation-form vesta-w-full vesta-flex-col-center">
                <div class="vesta-header-input-container vesta-w-full">
                  <div class="vesta-flex-row-start vesta-align-items-center vesta-w-full vesta-gap-5">
                    <svg-marker></svg-marker>
                    <estimation-input class="vesta-w-full"></estimation-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="typo-input vesta-text-center vesta-w-full vesta-text-center vesta-margin-t-5 vesta-margin-b-10">
              <svg-check></svg-check>
              100% gratuit et sans engagement
            </div>
          </div>
        </div>

        <div class="vesta-flex-col-center">
          <img auto="best" width="275" height="250" loading="lazy" alt="visual product" class="vesta-photo" ssl_detected="false" src="https://res.cloudinary.com/vesta-home/image/upload/v1702030269/assets/images/estim_result.png">
        </div>
      </div>
    </div>
  </div>
`.trim();

export const customButtonModalHtml = `
  <div style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1000; background: white; padding: 20px; border-radius: 8px; box-shadow: 0 4px 6px rgba(0,0,0,0.1);">
    <div style="position: absolute; top: 10px; right: 10px; cursor: pointer;" onclick="this.parentElement.parentElement.remove(); window.scrollTo(0, 0);">&times;</div>

    <div class='vesta-flex-col-center vesta-gap-10'>
      <div class='typo-h3'>Entrez le text et le lien du CTA :</div>
      <label class='vesta-text-left vesta-w-full' for='ctaText'>Text du CTA</label>
      <input type='text' id='ctaText' value='' placeholder="Découvrir l'offre vendeur" class='vesta-input vesta-input-blog-custom'>
      <label class='vesta-text-left vesta-w-full' for='ctaLink'>Lien du CTA</label>
      <input type='text' id='ctaLink' value='' placeholder='https://app.hosman.co/estimation' class='vesta-input vesta-input-blog-custom'>
      <button id='modalSubmit' class='vesta-button--primary'>Valider</button>
    </div>
  </div>
  <div style='position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5);'></div>
`.trim();

export const tabEstimMethodHtml = `
  <tab-estim-method>
    <table id='tab-estim-method-container'>
      <thead>
        <tr>
          <th>Méthode d'estimation</th>
          <th>Avantages</th>
          <th>Inconvénients</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td data-label="Méthode d'estimation">Estimation par soi-même</td>
          <td data-label="Avantages">
            <ul>
              <li>Coût nul, méthode gratuite</li>
              <li>Facilité d'accès : peut être réalisée à tout moment</li>
              <li>Permet de se familiariser avec le marché local</li>
            </ul>
          </td>
          <td data-label="Inconvénients">
            <ul>
              <li>Précision limitée : difficile sans expertise immobilière</li>
              <li>Exige de bien connaître le marché local</li>
              <li>Risque d'erreurs : comparaison parfois approximative avec les biens similaires</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td data-label="Méthode d'estimation">Estimation via un outil en ligne</td>
          <td data-label="Avantages">
            <ul>
              <li>Rapide et accessible : estimation instantanée et accessible partout</li>
              <li>Coût souvent réduit, voire gratuit pour des estimations de base</li>
              <li>Pratique pour obtenir une première idée de la valeur</li>
            </ul>
          </td>
          <td data-label="Inconvénients">
            <ul>
              <li>Précision variable : certains simulateurs manquent de précision</li>
              <li>Limitations des algorithmes : moins fiable qu'une estimation humaine</li>
              <li>Requiert souvent une validation par un expert pour une estimation définitive</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td data-label="Méthode d'estimation">Estimation via un professionnel</td>
          <td data-label="Avantages">
            <ul>
              <li>Très précis : expertise et connaissance du marché local</li>
              <li>Prise en compte de toutes les spécificités du bien</li>
              <li>Crédibilité renforcée pour les acheteurs lors de la vente</li>
            </ul>
          </td>
          <td data-label="Inconvénients">
            <ul>
              <li>Coût plus élevé (parfois inclus dans les services d'une agence immobilière ou d'un notaire)</li>
              <li>Délai parfois plus long qu'une estimation en ligne</li>
              <li>Nécessite un rendez-vous avec un professionnel</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </tab-estim-method>
`.trim();
