import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    words: {
      type: Array,
      default: ['Efficace.', 'Humaine.', 'Digitalisée.']
    }
  }

  connect() {
    this.currentIndex = 0
    this.element.style.transition = 'opacity 0.5s ease-in-out'
    this.startRotation()
  }

  disconnect() {
    if (this.interval) clearInterval(this.interval)
  }

  startRotation() {
    this.interval = setInterval(() => {
      this.rotate()
    }, 4000)
  }

  rotate() {
    this.element.style.opacity = 0

    setTimeout(() => {
      this.currentIndex = (this.currentIndex + 1) % this.wordsValue.length
      this.element.textContent = this.wordsValue[this.currentIndex]
      this.element.style.opacity = 1
    }, 500)
  }
}
